<!-- 聽力測驗題目及答案 -->
<template>
  <div class="TopicHearingAnswer">
    <!-- 題型title -->
    <h1 class="TopicHearingAnswer-h1">
      {{ dataForm.group_title }}. 聽力（{{ partTitle }}）
      <!-- {{ dataForm.progressNum }}.聽力（{{ partTitle }}） -->
    </h1>
    <div class="TopicHearingAnswer-box">
      <!-- 題目-STA -->
      <!-- 題目類型 1 音頻+圖片 -->
      <div class="box-left topic1" ref="topicRef1" v-if="topicType == 5">
        <img class="box-left-img" :src="topicData.image_uri" alt="">
        <div class="box-left-audio">
          <div class="audio-time">
            <span>{{ audioCurrent }}</span>
            <span>{{ audioTotal }}</span>
          </div>
          <div class="audio-progress">
            <el-slider v-model="audioPlan" :show-tooltip="false" disabled></el-slider>
            <!-- <div class="progress-round" :style="{ left: audioPlan + '%' }"></div>
            <el-progress :percentage="audioPlan" :show-text="false"></el-progress> -->
            <audio ref="singeBox"></audio>
          </div>
        </div>
      </div>
      
      <!-- 題目類型 2 音頻 -->
      <div class="box-left topic2" ref="topicRef2" v-if="topicType == 3">
        <div class="box-left-audio">
          <div class="audio-time">
            <span>{{ audioCurrent }}</span>
            <span>{{ audioTotal }}</span>
          </div>
          <div class="audio-progress">
            <el-slider v-model="audioPlan" :show-tooltip="false" disabled></el-slider>
            <!-- <div class="progress-round" :style="{ left: audioPlan + '%' }"></div>
            <el-progress :percentage="audioPlan" :show-text="false"></el-progress> -->
            <audio ref="singeBox"></audio>
          </div>
        </div>
      </div>
      <!-- 題目-END -->

      <!-- 答案-STA -->
      <!-- 答案類型 1 選項+文字 -->
      <div class="box-right answer1" ref="answerRef1" v-if="answerType == 1">
        <div
          :class="{
            'right-box': true,
            'active': answerCheck == item.option_title,
          }"
          v-for="(item, index) in topicData.option"
          :key="index"
          @click="typeCheck(item.option_title)"
        >
          <span>{{ item.option_title }}</span>
          <p v-if="item.show_type == 1 && item.content" v-html="item.content"></p>
        </div>
      </div>

      <!-- 答案類型 2 纯選項 -->
      <div class="box-right answer2" ref="answerRef3" v-if="answerType == 2">
        <div>
          <div
            :class="{
              'right-box': true,
              'active': answerCheck == item.option_title,
            }"
            v-for="(item, index) in topicData.option"
            :key="index"
            @click="typeCheck(item.option_title)"
          >
            <span>{{ item.option_title }}</span>
          </div>
        </div>
      </div>
      
      <!-- 答案類型 3 選項+圖片 -->
      <div class="box-right answer3" ref="answerRef2" v-if="answerType == 3">
        <div
          :class="answerCheck == item.option_title ? 'right-box active' : 'right-box'"
          v-for="(item, index) in topicData.option"
          :key="index"
          @click="typeCheck(item.option_title)"
        >
          <div>
            <img :src="item.image_uri" alt="">
          </div>
          <span>{{ item.option_title }}</span>
        </div>
      </div>
      <!-- 答案-END -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: 'TopicHearingAnswer',
  props: {
    partTitle: {
      type: String,
      default: ''
    },
    dataForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // 題目類型
      topicType: 1,
      // 題目類型
      answerType: 1,
      // 題目數據
      topicData: {},

      // 音頻對象
      audioBox: '',
      // 音頻總時長
      audioTotal: '00.00',
      // 音頻當前播放時長
      audioCurrent: '00.00',
      // 音頻進度條
      audioPlan: 0,

      // 答案選中
      answerCheck: '',
    }
  },
  computed: {
    ...mapGetters(['problemPopupStatus']),
  },
  watch: {
    dataForm: {
      handler() {
        // 初始化的時候根據判斷渲染數據
        if (this.dataForm.group_type == 1) { // 1單體形式  2題組形式
          const data = this.dataForm.question?.[0]
          // 數據賦值
          this.topicData = data
          // 題目類型賦值
          this.topicType = data.show_type
          // 答案賦值
          this.answerCheck = data.answer?.answer

          // 答案類型賦值
          if (data.option?.[0].show_type == 2) {
            this.answerType = 3
          } else if (data.option?.[0].show_type == 6) {
            this.answerType = 2
          } else {
            this.answerType = 1
          }
        } else {
          
        }
      },
      deep: true,
      immediate: true
    },
    
    // 監聽問題回報彈窗 同步暫停/開啟音頻
    problemPopupStatus(val) {
      if (val) {
        this.audioBox.pause()
      } else {
        this.audioBox.play()
      }
    }
  },
  methods: {
    // 播放初始化
    audioPlay() {
      this.$nextTick(() => {
        this.audioBox.play()
      })
    },
    // 音頻初始化
    audioInit() {
      this.audioBox = this.$refs.singeBox
      this.audioBox.src = this.topicData.audio_uri
      const _that = this
      // 当时长有变化时触发，由"NaN"变为实际时长也算
      this.audioBox.ondurationchange = function() {
        console.log('时长发生了变化')
        _that.updateTime()
      }
      // 当前数据可用是触发
      this.audioBox.oncanplay = function() {
        console.log('已经可以播放了')
      }
      // 播放位置发送改变时触发。
      this.audioBox.ontimeupdate = function() {
        console.log('播放位置发送了变动')
        _that.updateTime()
      }
      // 音频播放完毕
      this.audioBox.onended = function() {
        console.log('播放完毕，谢谢收听')
        _that.$emit('playChange')
        // this.audioCurrent = 0.00
        // this.audioPlan = 0
      }
      // 音频播放完毕
      this.audioBox.onerror = function() {
        console.log('加载出错！')
      }
    },
    // 更新时间
    updateTime() {
      const total = this.formatTime(this.audioBox.duration)
      const current = this.formatTime(this.audioBox.currentTime)
      this.audioTotal = `${total.min}:${total.sec}`
      this.audioCurrent = `${current.min}:${current.sec}`
      this.audioPlan = Number(this.audioBox.currentTime / this.audioBox.duration * 100) || 0
    },
    // 格式化毫秒，返回String型分秒对象
    formatTime(time) {
      // 有可能没获取到，为NaN
      if (!time) return { min: '00', sec: '00' }
      return {
        min: Math.floor(time / 60).toString().padStart(2, '0'),
        sec: Math.floor(time % 60).toString().padStart(2, '0')
      }
    },
    // 答案選中
    typeCheck(id) {
      this.answerCheck = id
      this.$emit('answerChange', this.answerCheck)
    },
    
    // 頁面滾動到頂部
    toRoll() {
      // 初始化的时候答案區塊滚动条置顶
      if (this.$refs.answerRef1) this.$refs.answerRef1.scrollTop = 0
      if (this.$refs.answerRef2) this.$refs.answerRef2.scrollTop = 0
      if (this.$refs.answerRef3) this.$refs.answerRef3.scrollTop = 0
      // 初始化的时候問題區塊滚动条置顶
      if (this.$refs.topicRef1) this.$refs.topicRef1.scrollTop = 0
      if (this.$refs.topicRef2) this.$refs.topicRef2.scrollTop = 0
    },
  }
}
</script>

<style lang="less" scoped>
.TopicHearingAnswer {
  width: 100%;
  height: 100%;
  .TopicHearingAnswer-h1 {
    padding-bottom: 20px;
    font-family: "Microsoft JhengHei","微軟正黑體";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: #333333;
  }
  .TopicHearingAnswer-box {
    width: 100%;
    height: calc(100% - 55px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-left {
      width: calc(50% - 10px);
      height: 100%;
      overflow: auto;
    }
    .box-right {
      width: calc(50% - 10px);
      height: 100%;
      overflow: auto;
    }
    .topic1 {
      text-align: center;
      .box-left-img {
        width: 390px;
        height: 390px;
      }
      .box-left-audio {
        margin: 10px auto 0;
        padding: 16px 13px;
        width: 364px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #3774E4;
        border-radius: 8px;
        .audio-time {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #4F4F4F;
        }
        .audio-progress {
          width: 100%;
          height: 8px;
          position: relative;
          .progress-round {
            position: absolute;
            top: -4px;
            width: 16px;
            height: 16px;
            z-index: 1;
            transform: translateX(-50%);
            border-radius: 50%;
            background: #4277FF;
            box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
          }
          /deep/ .el-progress-bar__outer {
            height: 8px !important;
            background-color: #D3D3D3;
          }
          /deep/ .el-progress-bar__inner {
            background-color: unset;
            background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
          }
          /deep/ .el-slider__runway {
            margin: 0;
            width: 100%;
            height: 12px;
            background-color: #D3D3D3;
            border-radius: 12px;
            .el-slider__bar {
              width: 100%;
              height: 100%;
              border-radius: 12px;
              background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
            }
          }
          /deep/ .el-slider__button-wrapper {
            margin-top: 11px;
            width: 16px;
            height: 16px;
            z-index: 2;
            // border-radius: 50%;
            // background: #4277FF;
            // box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
            .el-slider__button {
              width: 16px;
              height: 16px;
              border: none;
              background: #4277FF;
              box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
            }
          }
        }
      }
    }
    .topic2 {
      .box-left-audio {
        margin-top: 60px;
        padding: 30px 20px;
        width: 558px;
        height: 55px;
        background: #FFFFFF;
        border: 1px solid #3774E4;
        border-radius: 8px;
        .audio-time {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #4F4F4F;
        }
        .audio-progress {
          width: 100%;
          height: 12px;
          position: relative;
          .progress-round {
            position: absolute;
            top: -2px;
            width: 16px;
            height: 16px;
            z-index: 1;
            transform: translateX(-50%);
            border-radius: 50%;
            background: #4277FF;
            box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
          }
          /deep/ .el-progress-bar__outer {
            height: 12px !important;
            background-color: #D3D3D3;
          }
          /deep/ .el-progress-bar__inner {
            background-color: unset;
            background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
          }
          /deep/ .el-slider__runway {
            margin: 0;
            width: 100%;
            height: 12px;
            background-color: #D3D3D3;
            border-radius: 12px;
            .el-slider__bar {
              width: 100%;
              height: 100%;
              border-radius: 12px;
              background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
            }
          }
          /deep/ .el-slider__button-wrapper {
            margin-top: 11px;
            width: 16px;
            height: 16px;
            z-index: 2;
            // border-radius: 50%;
            // background: #4277FF;
            // box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
            .el-slider__button {
              width: 16px;
              height: 16px;
              border: none;
              background: #4277FF;
              box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
            }
          }
        }
      }
    }
    .answer1 {
      width: calc(50% - 60px);
      height: calc(100% - 50px);
      padding: 50px 30px 0 30px;
      .right-box {
        cursor: pointer;
        user-select: none;
        padding: 10px 0;
        display: flex;
        width: 100%;
        span {
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          background: #EEEEEE;
          border-radius: 50%;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 34px;
          text-align: center;
          color: #333333;
        }
        p {
          width: calc(100% - 58px);
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 48px;
          color: #000000;
        }
      }
      .active {
        span {
          background: #0C4FA2;
          color: #FFFFFF;
        }
        p {
          color: #0C4FA2;
        }
      }
      .isOptions {
        display: flex;
        justify-content: center;
      }
    }
    .answer2 {
      display: flex;
      align-items: center;
      justify-content: center;
      .right-box {
        width: 100%;
        cursor: pointer;
        user-select: none;
        padding: 10px 0;
        display: flex;
        width: 100%;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          background: #EEEEEE;
          border-radius: 50%;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 34px;
          text-align: center;
          color: #333333;
        }
      }
      .active {
        span {
          background: #0C4FA2;
          color: #FFFFFF;
        }
      }
    }
    .answer3 {
      padding-top: 60px;
      height: calc(100% - 60px);
      display: flex;
      justify-content: space-between;
      .right-box {
        cursor: pointer;
        user-select: none;
        div {
          width: 195px;
          height: 195px;
          border: 2px solid transparent;
          img {
            width: 100%;
            height: 100%;
          }
        }
        span {
          margin: 16px auto 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          background: #EEEEEE;
          border-radius: 50%;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 37px;
          color: #333333;
        }
      }
      .right-box:last-child {
        margin-right: 0;
      }
      .active {
        div {
          border: 2px solid #0C4FA2;
        }
        span {
          background: #0C4FA2;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>